import { useEffect, useState, useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import { uscall/*, getresourceurl, getfirst, usurl*/ } from '../../../us';

function DiscoverBox({areacode}) {
    const auth = useAuth();
    const [box,setBox]=useState(null);
    const [products,setProducts]=useState(null);
    const appcontext = useContext(AppContext);
    useEffect(() => {
	const cb=async function(){
		const r=await uscall(appcontext,auth,'ecommerce','boxdiscover','getdiscover',null,{areacode:areacode});
		if(typeof(r['error'])!='undefined');
		else if(typeof(r['result'])!='undefined' && r.result)setBox(r.result);
	}
	if(box);else if(areacode)
		cb();
    },[box,areacode,auth,appcontext,appcontext.manifest.relations]);
    useEffect(() => {
	const cb=async function(){
		let filter=[];
		for(let k in box)filter.push(box[k].isbn);
		const ps=await uscall(appcontext,auth,'ecommerce',null,'magento_getbooks',null,{additionalfilters:{isbn:filter},limit:filter.length});
		if(typeof(ps['error'])!='undefined')return;
		setProducts(ps.result);
	};
	if(products);else if(box)cb();
    },[box]);
	const now=(new Date()).valueOf();

    
    return (
        <>
            <div className='dash-box discover-box h-460'>
                <h1 className='m-0'>Ciao {auth.user?.profile.given_name}, scopri le novità del catalogo</h1>
                <div className="row g-0">
			{products?(<>
				{products.map((product,idx)=>(
                                <div className="col" key={idx}>
                                    <a href={product.magento_custom && product.magento_custom.linkrivista?product.magento_custom.linkrivista:product.url}>
                                        <div className='prod-cover'>
					    {product.pubdate-now<7*24*60*60*1000?(<button className='chip'><span>Novità</span></button>):(<></>)}
                                            <img src={(product?.magento_custom?.image)?(product?.magento_custom?.image):`${process.env.PUBLIC_URL}/placeholder.png`} />
                                            <div className='overlay'>
                                                <h6>{product.name}</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
				))}
			</>):(<>
                                <div className="col-12 col-md-4">
                                    <div className='prod-cover'>
                                        <img src={`${process.env.PUBLIC_URL}/placeholder.png`} />
                                        <div className='overlay'>
                                            <h6></h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className='prod-cover'>
                                        <img src={`${process.env.PUBLIC_URL}/placeholder.png`} />
                                        <div className='overlay'>
                                            <h6></h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className='prod-cover'>
                                        <img src={`${process.env.PUBLIC_URL}/placeholder.png`} />
                                        <div className='overlay'>
                                            <h6></h6>
                                        </div>
                                    </div>
                                </div>
			</>)}

                </div>
                <div className='row g-0 w-100 mt-2'>
                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/novita`} className='gradient-button align-self-center'>Sfoglia il catalogo</a>
                </div>
            </div>
        </>
    )
}

export default DiscoverBox;

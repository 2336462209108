import './landing.css';
import BoxLanding from './box-landing';
import contentData from './ContentBanner';

function BannerLanding({ landingPage }) {
    const content = contentData[landingPage] || contentData.default;

    return (
        <section className="banner-landing" style={{ backgroundImage: content.backgroundImage }}>
            <div className='banner-overlay'></div>
            <div className='banner-headings'> 
                <h1 dangerouslySetInnerHTML={{ __html: content.mainTitle }}></h1>
                <h2 dangerouslySetInnerHTML={{ __html: content.subTitle }}></h2>
            </div>
            <BoxLanding landingPage={landingPage} />
        </section>
    );
}

export default BannerLanding;

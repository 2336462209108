import {formatprice,formatdate,translateproducttype,translatepaymentmethod} from '../../us.js';
import { Link} from "react-router-dom";

const SinglePayment=({order,producttypes,payment})=>{
	let types=[];
	for(let i=0;i<order.items.length;i++){
		if(typeof(order.items[i]['product'])!='undefined');else continue;
		let t=translateproducttype(producttypes,order.items[i].product.type);
		if(order.items[i].product && order.items[i].product.type && (!types.includes(t))){
			types.push(t);
		}
	}
	const linkricevuta=(payment && payment.uuid?'/ricevuta/'+encodeURIComponent(order.uuid)+'/'+encodeURIComponent(payment.uuid):'/ricevuta/'+encodeURIComponent(order.ordercode));
	return (
                <div className='history-item'>
                    <div className='row w-100 g-0'>
                        <div className='col'>
                            <h5>{types.join(', ')}</h5>
                            <h3>Numero d'ordine: {order.ordercode}</h3>
                            <div className='d-flex flex-direction-row mb-2'>
                                <span>Modalità di pagamento</span>
				{payment.method==='stripecheckout' || payment.method==='stripesetup'?(<>
                                <img src={payment.brand?`${process.env.PUBLIC_URL}/creditcards/`+payment.brand+`.svg`:`${process.env.PUBLIC_URL}/creditcards/mastercard.svg`} alt={payment.brand?payment.brand:translatepaymentmethod(payment.method)} />
                                <p className='m-0'><span>**** {payment.last4}</span></p>
				</>):(<>{translatepaymentmethod(payment.method)}</>)}
                            </div>
                            <div className='d-flex flex-direction-row'>
                                <span>Data di pagamento</span>
                                <p className='m-0'><span>{formatdate(order.created)}</span></p>
                            </div>
                        </div>
                        <div className='col-auto download'>
                            {/*<Link to={linkricevuta}><img className='m-0' src={`${process.env.PUBLIC_URL}/download.svg`} alt="Download" /></Link>*/}
                            <div className='d-flex flex-direction-row align-items-center'>
                                <span>Importo</span>
                                <p className='m-0'><span>{formatprice(order.total)}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
);
};
const Payment=({order,producttypes})=>{
	return (<>{typeof(order.payment['length'])!='undefined'?(<>
		{order.payment.map(p=>{return (<SinglePayment order={order} producttypes={producttypes} payment={p} />);})}
		</>):(<SinglePayment order={order} producttypes={producttypes} payment={order.payment} />)}
</>);
};
export {SinglePayment};
export default Payment;

import { useEffect,useState,useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
import {/*uscall,*/getresourceurl/*,getfirst,usurl*/} from '../../us';
import Payment from './payment';
import './myproducts.css';
import {useNavigate} from 'react-router-dom';

const MyProduct = ({order,item,producttypes}) => {
	const navigate=useNavigate();
    const auth = useAuth();
    const appcontext = useContext(AppContext);
	const [product,setProduct]=useState(null);
	const [copertina,setCopertina]=useState(null);
	const [desc,setDesc]=useState(null);
	const [orderstatus,setOrderStatus]=useState(null);
	const [producttype,setProductType]=useState(null);
	const [isOpen,setIsOpen]=useState(false);


	useEffect(()=>{
		setOrderStatus(order.status);
	},[order]);
	useEffect(()=>{
		const cb=async function(){
			/*let filter=null;
			if(typeof(item.product.magento_custom['isbn'])!='undefined' && typeof(item.product.magento_custom['issn'])!='undefined')
				filter=({func:'and',fields:[{field:'isbn',func:'eq',value:item.product.magento_custom.isbn},{field:'issn',func:'eq',value:item.product.magento_custom.issn}]});
			else if(typeof(item.product.magento_custom['isbn'])!='undefined')
				filter=(item.product.magento_custom.isbn);
			else if(typeof(item.product['magento_sku'])!='undefined')
				filter=(item.product['magento_sku']);
			r=getfirst(await uscall(appcontext,auth,'ecommerce',null,'product_search',null,{filter:filter,limit:1}));*/
			const r=item.product;
			setProduct(r);
			if(r){
				if(r.magento_custom && r.magento_custom.image)setCopertina(r.magento_custom.image)
				else if(r.copertina)setCopertina(await getresourceurl(appcontext,auth,r.copertina));
			}
		};
		/*if(product);else*/ cb();
	},[order,item,product,appcontext,auth]);
	useEffect(()=>{
		//if(!producttype){
			if(product && producttypes){
				if(typeof(producttypes[product.type])!=='undefined'){
					setProductType(producttypes[product.type]);
					return;
				}
				for(let k in producttypes){
					if(producttypes[k].code===product.type){
						setProductType(producttypes[k]);
						break;
					}
				}
			}
		//}
	},[product,producttypes]);
	useEffect(()=>{
		if(product){
			if(typeof(product['magento_custom'])!=='undefined'){
				if(typeof(product.magento_custom['isbn'])!=='undefined' && typeof(product.magento_custom['issn'])!=='undefined')
					setDesc({isbn:product.magento_custom.isbn,issn:product.magento_custom.issn,sku:null});
				else if(typeof(item.product.magento_custom['isbn'])!=='undefined')
					setDesc({isbn:product.magento_custom.isbn,issn:null,sku:null});
				else if(typeof(item.product['magento_sku'])!=='undefined')
					setDesc({isbn:null,issn:null,sku:product.magento_sku});
				else
					setDesc(null);
			}else{
					setDesc({isbn:product.isbn,issn:product.issn,sku:(product.ean?product.ean:product.code)});
			}
		}
	},[product,item]);

	const SwapOpen=function(event){
		event.stopPropagation();
		event.preventDefault();
		setIsOpen(!isOpen);
	};

	const reBuy=function(event){
		event.stopPropagation();
		event.preventDefault();
		const mycode=(desc.issn?desc.issn:(desc.isbn?desc.isbn:desc.sku));
		if(mycode==='abbonamentoedu')
			navigate('/create-checkout/'+encodeURIComponent(mycode));
		else
			document.location.href=product.url;
	}

//if(orderstatus=='payed'){console.log('            Rendersingle '+order.ordercode);}

	return (<>{orderstatus && orderstatus!=='new' && orderstatus!=='waitforpayment'?(<>
                            <div className='product-container' onClick={SwapOpen}>
				<>{producttype && product?(<>
                                <div className='row row-top w-100'>
                                    <div className='col p-0'>
                                        <h4>{producttype.name}</h4>
					{orderstatus==='payed'?(<>
						<div className='state active'>
							<img src={`${process.env.PUBLIC_URL}/state-active.svg`} alt="Attivo" />
							<span>Attivo</span>
						</div></>):(<>{orderstatus==='cancelled'?(<>
							<div className='state inactive'>
								<img src={`${process.env.PUBLIC_URL}/state-inactive.svg`} alt="Cancellato" />
								<span>Cancellato</span>
							</div>
						</>):(<>{/*TODO:pending*/}
								<div className='state returned'>
									<img src={`${process.env.PUBLIC_URL}/state-returned.svg`} alt="Restituito" />
									<span>{orderstatus}</span>
								</div>
						</>)}
					</>)}
					</div>
                                </div>
                                <div className='row g-0 w-100'>
                                    <div className='col-auto p-0'>
                                        <img className='cover' src={copertina?copertina:`${process.env.PUBLIC_URL}/placeholder.png`} alt="Copertina" />
                                    </div>
                                    <div className='col'>
                                        <h3>{product.name}</h3>
                                        <p>{desc?(<>{desc.issn?(<>ISBN <span>{desc.isbn}</span>, ISSN <span>{desc.issn}</span></>):(<>{desc.isbn?(<>ISBN <span>{desc.isbn}</span></>):(<>SKU <span>{desc.sku}</span></>)}</>)}</>):null}</p>
                                        {/*TODO<p>Scade il <span>30 Novembre 2024</span></p>*/}
                                    </div>
                                    <div className='col-md-auto d-flex d-md-block'>
					<>{orderstatus==='payed'?(<>
					{/*TODO: se non è un abbonamento / EDU eBook / Rivista */}
                                        <button className='gradient-button'><span>Ricevuta</span></button>     
					{/*TODO: altrimenti*/}
                                        {/*<button className='gradient-button'><span>Gestisci</span></button>*/}
					{/*/TODO: se non è un abbonamento / EDU eBook */}
                                        {/*TODO: nei primi15gg se mai aperto <button className='gradient-button secondary-button'><span>Rimborso</span></button>*/}
                                        {/*TODO: solo per EDU eBook <button className='gradient-button secondary-button'><span>Cambia eBook</span></button>*/}
					</>):(<>
                                        <button className='gradient-button' onClick={reBuy}><span>Acquista di nuovo</span></button>     
                                        <button className='gradient-button secondary-button'><span>Ricevuta</span></button>
					</>)}</>
                                    </div>
                                </div>
				</>):null}</>
                            </div>
			{isOpen && order.payment?(
            			<div className='purchase-history'>
				<Payment order={order} producttypes={producttypes} />
				</div>):null}

		</>):null}
</>);
}

export default MyProduct;
